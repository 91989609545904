import Breadcrumbs from '@finn/auto-ui/components/Breadcrumbs';
import {
  interactionTrackingEvent,
  LinkLocation,
  TrackingEventName,
} from '@finn/ua-tracking';
import React from 'react';

import { Breadcrumb } from './useBlogBreadcrumbs';

const trackBreadcrumbClick = (breadcrumb: Breadcrumb) => {
  interactionTrackingEvent(TrackingEventName.LINK_CLICKED, {
    location: LinkLocation.BLOG_BREADCRUMB,
    name: breadcrumb.title,
  });
};

type Props = {
  breadcrumbs: Breadcrumb[];
};
const BlogBreadcrumbs: React.FC<Props> = ({ breadcrumbs }: Props) => {
  if (!breadcrumbs) {
    return null;
  }

  return (
    <div className="container">
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        onBreadcrumbClick={trackBreadcrumbClick}
      />
    </div>
  );
};

export default BlogBreadcrumbs;
