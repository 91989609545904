import { GenericVehicleDetails } from '@finn/ua-vehicle';
import {
  buildCosmicPageObjectTree,
  useIsCosmicHelperEnabled,
} from '@finn/ui-cosmic';
import React from 'react';

import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import CosmicHelper from '~/ui-modules/PageBodyBuilder/CosmicHelper';

type Props = {
  data: any;
  preview: boolean;
  localizationObjects?: any;
  vehicle?: GenericVehicleDetails;
};

const CosmicHelperWrapper: React.FC<Props> = ({
  data,
  preview,
  localizationObjects,
  vehicle,
}) => {
  const isCosmicHelperEnabled = useIsCosmicHelperEnabled();
  const { locale } = useCurrentLocale();
  if (!isCosmicHelperEnabled) return null;
  const tree = buildCosmicPageObjectTree(data);
  console.log({ CosmicRawData: data });
  if (localizationObjects && Array.isArray(tree.children)) {
    tree.children.push(localizationObjects[locale]);
  }

  return (
    <div id="cosmic-helper-container">
      <CosmicHelper tree={tree} preview={preview} vehicle={vehicle} />
    </div>
  );
};
export default CosmicHelperWrapper;
