// TODO this module has direct connection to many other modules, which is antipattern
// let's refactor it

import { hasNoPadding, SpacingWrapper } from '@finn/platform-modules';
import { Brand } from '@finn/ua-vehicle';
import { ErrorBoundary } from '@finn/ui-components';
// TODO delete this import after refactoring PageBodyBuilder
import { CosmicModuleType, ModuleType } from '@finn/ui-cosmic';
import { FakeDefaultLocale } from '@finn/ui-utils';
import React, { memo, ReactNode } from 'react';

import CosmicHelperWrapper from '~/components/CosmicHelperWrapper/CosmicHelperWrapper';
import HeadTags from '~/components/HeadTags';
import { PageDataProvider } from '~/contexts/PageData';
import Page from '~/layouts/Default';
import { BasePageData } from '~/types/cosmicModules';
import { Review } from '~/types/reviews';
import CosmicModule from '~/ui-modules/CosmicModule';
import {
  buildBreadcrumbsJsonLdAsStr,
  buildFAQJsonLdAsStrForPage,
} from '~/utils/html';

import BlogBreadcrumbs from '../BlogBreadcrumbs';
import { useBlogBreadcrumbs } from '../BlogBreadcrumbs/useBlogBreadcrumbs';
import { StickyNavContent } from '../StickyNav/types';

type Props = {
  data: BasePageData;
  reviews?: Review[];
  brands?: Brand[];
  children?: ReactNode;
  stickyNavContent?: StickyNavContent;
  preview?: boolean;
  renderMetaContent?: () => ReactNode;
  hasNoPaddingTop?: boolean;
  jsonLdContentArr?: string[];
};

const isSticky = (type: CosmicModuleType): boolean => {
  return type === CosmicModuleType.WEB_MODULE_HERO_STICKY_NAVS;
};

const PageBodyBuilder: React.FunctionComponent<Props> = ({
  data,
  reviews,
  brands,
  children,
  stickyNavContent,
  preview,
  renderMetaContent,
  hasNoPaddingTop,
  jsonLdContentArr = [],
}) => {
  const { modules, seo_header } = data.metadata;
  const faqJsonldAsString = buildFAQJsonLdAsStrForPage(modules);
  const blogBreadcrumbs = useBlogBreadcrumbs({
    seoHeaderData: data.metadata.seo_header,
  });
  if (faqJsonldAsString) {
    jsonLdContentArr.push(faqJsonldAsString);
  }
  if (blogBreadcrumbs) {
    jsonLdContentArr.push(
      // blogBreadcrumbs urls already contain locale so we don't need to add it
      buildBreadcrumbsJsonLdAsStr(blogBreadcrumbs, FakeDefaultLocale)
    );
  }

  return (
    <>
      <PageDataProvider reviews={reviews} brands={brands}>
        <CosmicHelperWrapper data={data} preview={preview} />
        <Page data={data}>
          <HeadTags seoHeader={seo_header} jsonLdContent={jsonLdContentArr} />
          {renderMetaContent?.()}
          <BlogBreadcrumbs breadcrumbs={blogBreadcrumbs} />
          {modules.map((module: ModuleType) => (
            <ErrorBoundary key={module.id || module.type}>
              {isSticky(module.type) ? (
                <CosmicModule
                  data={module}
                  stickyNavContent={
                    module.type === CosmicModuleType.WEB_MODULE_HERO_STICKY_NAVS
                      ? stickyNavContent
                      : undefined
                  }
                />
              ) : (
                <SpacingWrapper
                  noPaddingTop={hasNoPaddingTop || hasNoPadding(module.type)}
                  html_id={module.metadata?.html_id}
                >
                  <CosmicModule data={module} />
                </SpacingWrapper>
              )}
            </ErrorBoundary>
          ))}
          {children}
        </Page>
      </PageDataProvider>
    </>
  );
};

export default memo(PageBodyBuilder);
